
export default {
    name: 'YfnCell',
    components: {},
    asyncData({api, cookies, ...context}) {},
    props: {
        options: Object
    },
    data() {
        return {
            rsOptions: {},
            defaultOptions: {
                group: {
                    border: false
                },
                cell: {}
            }
        }
    },
    computed: {},
    watch: {
        options: {
            handler(n, o) {
                const p = n ? JSON.parse(JSON.stringify(n)) : {};
                this.rsOptions = p
                this.setData()
            },
            deep: true,
            immediate: true
        }
    },
    created() {},
    mounted() {},
    methods: {
        setData() {
            // 键值对替换
            if(this.rsOptions.list && this.rsOptions.key) {
                const key = this.rsOptions?.key || {};
                const keyArr = Object.keys(key);
                this.rsOptions.list.forEach(item => {
                    keyArr.forEach(c => {
                        item[c] = item[key[c]] ?? ''
                    })
                })
            }
            // 数据处理
            const key_arr = ['title', 'value', 'label', 'size', 'icon', 'icon-prefix'];
            this.rsOptions.list.forEach(p => {
                let props = {};
                key_arr.forEach(c => {
                    p[c] !== undefined && (props[c] = p[c])
                })
                p.props = {...props, ...p.props}
            })
        },
        handleCell(obj) {
            this.$emit('click', obj)
        }
    },
}
