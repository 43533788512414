
export default {
    name: 'YfnCheckbox',
    components: {},
    asyncData({api, cookies, ...context}) {},
    props: {
        list: Array,
        dataKey: Object
    },
    data() {
        return {
            value: [],
            reList: [],
            options: {}
        }
    },
    computed: {},
    watch: {
        list: {
            handler() {
                this.setListData()
            },
            deep: true,
            immediate: true
        },
        '$attrs': function() {
            this.setBind()
        }
    },
    created() {},
    mounted() {},
    methods: {
        // 数据
        setListData() {
            const dataKey = this.dataKey || {};
            const list = this.list || [];
            this.reList = list.map(p => {
                let o = {...p};
                Object.keys(dataKey).forEach(c => {
                    o[c] ?? (o[c] = p[dataKey[c]])
                })
                return o
            })
        },
        setBind() {
            const defaultOptions = {
                size: 16,
                type: 'border',
                'has-cancel': true,
                max: 9999,
                'label-position': 'left'
            };
            this.options = {
                ...defaultOptions,
                ...this.$attrs,
            }
            this.value = this.options.value
            this.setReListData()
        },
        // 设置
        handleRadio(obj) {
            const hasCancel = this.options['has-cancel'];
            const hasValue = this.value.includes(obj.value);
            const hasExchange = this.options['has-exchange'] && this.options.max == 1;
            const length = this.value.length;
            const valueStr = JSON.stringify([...this.value]);
            const disabled = this.options.disabled || obj.disabled;
            let value = [...this.value];
            if(!disabled) {
                if(hasValue && hasCancel) {
                    this.value = value.filter(p => {
                        return p != obj.value
                    })
                } else {
                    const hasAdd = length < this.options.max && !hasValue;
                    hasAdd && this.value.push(obj.value)
                    hasExchange && (this.value = [obj.value])
                }
                const hasChange = valueStr == JSON.stringify(this.value);
                this.$emit('input', this.value)
                !hasChange && this.$emit('change', this.value)
            }
            this.$emit('click', obj)
        },
        setReListData() {
            this.reList.forEach(p => {
                const hasSelect = this.value.includes(p.value);
                const checkedColor = this.options['checked-color'];
                const voidColor = this.options['void-color'];
                const disabled = this.options.disabled || p.disabled;
                const hasRight = this.options['label-position'] == 'right';
                p.liClass = [
                    'YfnCheckbox-radio',
                    {'YfnRadio-radio-right': hasRight},
                    {'YfnCheckbox-radio-active': hasSelect},
                    {'YfnCheckbox-radio-disabled': disabled}]
                p.liStyle = {
                    'border-radius': this.options.radius,
                    'color':  hasSelect ? checkedColor : voidColor,
                    'border-color': hasSelect ? checkedColor : voidColor
                }
                p.iconName = hasSelect ? 'icon-check-selected' : 'icon-check'
                p.iconFill = hasSelect ? checkedColor : voidColor
            })
        }
    },
}
