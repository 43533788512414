
export default {
    name: 'YfnField',
    components: {},
    asyncData({api, cookies, ...context}) {},
    props: {
        options: Object
    },
    data() {
        return {
            defaultOptions: {
                placeholder: this.$translate('Please enter'),
                closeable: true,
            }
        }
    },
    computed: {},
    watch: {},
    created() {},
    mounted() {},
    methods: {},
}
