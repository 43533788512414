
export default {
    name: 'YfnCountTo',
    components: {},
    asyncData({api, cookies, ...context}) {},
    props: {
        showSymbol: Boolean,
        currencySymbol: String
    },
    data() {
        return {}
    },
    computed: {},
    watch: {},
    created() {},
    mounted() {},
    methods: {
        setBind() {
            const currency = this.$storage.get('currency');
            const ds = ['€', 'pуб.'];
            const currencySymbol = this.currencySymbol || currency.symbol;
            let d = {
                decimals: 2,
                decimal: '.',
                prefix: '',
                suffix: ''
            };
            if(this.showSymbol) {
                if(ds.includes(currencySymbol)) {
                    d.suffix = currencySymbol
                    d.decimal = ','
                } else {
                    d.prefix = currencySymbol
                }
            }
            const merge = {...d, ...this.$attrs};
            return merge
        }
    },
}
