
export default {
    name: 'YfnCountScroll',
    components: {},
    asyncData({api, cookies, ...context}) {},
    props: {
        val: [String, Number],
        duration: [String, Number],
        delay: [String, Number],
        cancelScroll: Boolean,
        showSymbol: Boolean,
        currencySymbol: String
    },
    data() {
        return {
            visible: false,
            scrollData: [],
            biz: {
                prefix: '',
                suffix: ''
            }
        }
    },
    computed: {},
    watch: {
        val: {
            handler(n, o) {
                n && this.setData(n)
            },
            immediate: true
        }
    },
    created() {},
    mounted() {},
    methods: {
        setData(val) {
            const valArr = String(val).split('');
            let listArr = [];
            valArr.forEach(p => {
                const hasNum = ![',', '.'].includes(p);
                // 子级
                let child = [];
                if(hasNum) {
                    for(let i = 0; i < Number(p) + 1; i++) {
                        child.push(i)
                    }
                } else {
                    child.push(p)
                }
                listArr.push({
                    val: Number(p),
                    style: {},
                    hasNum,
                    child,
                })
            })
            this.scrollData = listArr
            this.visible = true
            this.setSymbol()
            this.resetData()
        },
        // 个性化设置
        resetData() {
            this.$nextTick(() => {
                if(this.delay) {
                    let clear = setTimeout(() => {
                        this.animateData()
                        clearTimeout(clear)
                    }, this.delay)
                } else {
                    this.animateData()
                }
            })
        },
        // 动画设置
        animateData() {
            const ref = this.$refs.YfnCountScrollVal || [];
            const firstRef = ref[0];
            const height = firstRef.clientHeight || 20;
            const duration = this.cancelScroll ? 0 : this.duration || 1;
            this.scrollData.forEach(p => {
                let style = {}
                if(p.hasNum) {
                    const y = height * p.val;
                    style = {
                        transform: `translateY(-${y}px)`,
                        transition: `transform ${duration}s ease-out`
                    }
                }
                p.style = style
            })
        },
        // 货币设置
        setSymbol() {
            this.biz.prefix = ''
            this.biz.suffix = ''
            if(!this.showSymbol) return
            const currency = this.$storage.get('currency');
            const ds = ['€', 'pуб.'];
            const currencySymbol = this.currencySymbol || currency.symbol;
            if(ds.includes(currencySymbol)) {
                this.biz.suffix = currencySymbol
            } else {
                this.biz.prefix = currencySymbol
            }
        }
    },
}
