
export default {
    name: 'YfnCollapse',
    components: {},
    asyncData({api, cookies, ...context}) {},
    props: {
        options: Object
    },
    data() {
        return {
            collapse: {
                val: this.options.accordion ? '' : []
            },
            rsOptions: {},
            defaultOptions: {
                group: {},
                collapse: {}
            }
        }
    },
    computed: {
        isServerRendered() {
            return process.server;
        }
    },
    watch: {
        options: {
            handler(n, o) {
                const p = n ? JSON.parse(JSON.stringify(n)) : {};
                this.rsOptions = p
                this.setData()
            },
            deep: true,
            immediate: true
        }
    },
    created() {},
    mounted() {},
    methods: {
        setData() {
            this.rsOptions.visible = !!this.rsOptions.list.length
            if(this.rsOptions.model) {
                this.collapse.val = this.rsOptions.model
            } else {
                this.collapse.val = this.rsOptions.accordion ? '' : []
            }
            // 键值对替换
            if(this.rsOptions.list && this.rsOptions.key) {
                const key = this.rsOptions?.key || {};
                const keyArr = Object.keys(key);
                this.rsOptions.list.forEach(item => {
                    keyArr.forEach(c => {
                        item[c] = item[key[c]] ?? ''
                    })
                })
            }
            // 数据处理
            const key_arr = ['name', 'icon', 'size', 'title', 'value', 'label', 'border', 'is-link', 'disabled', 'lazy-render', 'title-class', 'value-class', 'label-class'];
            this.rsOptions.list.forEach(p => {
                let props = {};
                key_arr.forEach(c => {
                    p[c] !== undefined && (props[c] = p[c])
                })
                p.props = {...props, ...p.props}
            })
        },
        // 右侧icon处理
        setRightIcon(e, val, i) {
            let hasActive = false;
            if(!this.rsOptions?.group?.accordion) {
                if(val.includes(e.name) || val.includes(i)) {
                    hasActive = true
                } else {
                    hasActive = false
                }
            } else {
                if(val == e.name || val == i) {
                    hasActive = true
                } else {
                    hasActive = false
                }
            }
            return hasActive ? 'icon-arrow-up' : 'icon-arrow-down'
        },
        changeCollapse(e) {
            this.$emit('change', e)
        },
        handleItem(obj) {
            this.$emit('click', obj)
        }
    },
}
