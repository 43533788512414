
export default {
    name: 'YfnFooter',
    props: {
        placeholder: {
            type: Boolean,
            default: () => {
                return true
            }
        }
    },
    data() {
        return {
            active: 0
        }
    },
    computed: {},
    watch: {},
    created() {},
    mounted() {},
    methods: {},
}
